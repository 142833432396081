import React from 'react'

// copied from 
// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const generateEmbed = videoId => `<div style="display:none;">
  <script src="https://cdn.coil.com/if-monetized.js"></script>
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/${videoId}"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</div>`

const EmbedCode = ({ videoId }) => {
  const embedCode = generateEmbed(videoId)

  return <>
    <pre className='embed'><code>{embedCode}</code></pre>
    <button
      className='btn btn-primary'
      onClick={() => {
        copyToClipboard(embedCode)
      }}
    >
      Copy Code to Clipboard
    </button>
  </>
}

export default EmbedCode
