import React, { useState } from 'react'
import EmbedCode from './EmbedCode'
import './App.css'

const urlToVideoId = url => {
  try {
    const parsed = new URL(url.match(/^https?:/)
      ? url
      : 'https://' + url)

    if (parsed.host === 'youtu.be') {
      return parsed.pathname.substring(1)
    } if (parsed.host === 'www.youtube.com') {
      return parsed.searchParams.get('v')
    }
  } catch (e) {
    return
  }
}


const App = () => {
  const [ urlInput, setUrlInput ] = useState('')
  const videoId = urlToVideoId(urlInput)

  return <div className="container main">
    <h1 className="h4">
      Youtube Embed
      <small className="text-muted"> with Web Monetization</small>
    </h1>

    <hr />

    <p>Paste a youtube video url into the textbox below. You'll get a snippet
    of code that you can paste into your site. Users who have Web Monetization
    enabled will see the embed, and other users will see nothing.</p>

    <p><a href='/example.html'>See an example here</a></p>

    <p>You could use this with an unlisted Youtube video to give exclusive
    content to Web Monetized users. Be careful, though! <strong>All code is sent to
    the client, so a tricky user could access it without really having Web
    Monetization.</strong></p>

    <div className="url-input-container form-group d-flex">
      <input
        autofocus
        className="form-control"
        type='text'
        value={urlInput}
        onChange={ev => {
          setUrlInput(ev.target.value)
        }}
      />

      <div className="spacer">&nbsp;</div>

      <button 
        className="btn btn-danger"
        onClick={() => {
          setUrlInput('')
        }}
      >
        Clear
      </button>
    </div>

    <div className="embed-container">
      {videoId
        ? <EmbedCode videoId={videoId} />
        : <p>Enter a Youtube URL to get your embed code.</p>}
    </div>
  </div>
}

export default App
